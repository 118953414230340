<template>
  <Transition
    appear
    data-test="soft-prompt-transition"
    enter-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-y-0 md:translate-y-auto md:translate-x-0"
    enter-from-class="!opacity-0 translate-y-[100%] md:translate-y-0 md:translate-x-[100%]"
    leave-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-y-0 md:translate-y-auto md:translate-x-0"
    leave-to-class="!opacity-0 translate-y-[100%] md:translate-y-0 md:translate-x-[100%]"
  >
    <div
      v-if="canDisplayPrompt"
      class="fixed z-20 md:right-12"
      :class="getVerticalPosition"
      data-test="soft-prompt"
    >
      <RevCard
        class="!shadow-long flex w-full flex-col items-center bg-[#F1EEE9] px-24 py-32 md:w-[458px]"
      >
        <RevButtonIcon
          :aria-label="i18n(translations.close)"
          class="absolute right-12 top-12"
          :icon="IconCross"
          variant="secondary"
          @click="onPromptClosed"
        />

        <div class="my-16 text-center">
          <FormattedMessage
            class="text-static-default-hi heading-1 mb-4 mt-16"
            :definition="translations.title"
            tag="h3"
          >
            <template #italicText>
              <span class="heading-1-italic">
                {{ i18n(translations.titleItalicText) }}
              </span>
            </template>
          </FormattedMessage>

          <span class="text-static-default-mid body-2">
            {{ i18n(translations.message) }}
          </span>
        </div>

        <div class="relative h-[120px] w-[141px] md:h-[200px] md:w-[184px]">
          <div class="hidden md:block">
            <picture>
              <RevIllustrationSource
                :height="200"
                :media="BreakpointQueries[Breakpoint.XS]"
                src="/img/prompt/gradient-phone.webp"
                type="image/webp"
                :width="184"
              />
              <RevIllustration
                alt=""
                class="absolute"
                :height="200"
                src="/img/prompt/gradient-phone.png"
                :width="184"
              />
            </picture>
            <RevButtonBase
              class="absolute right-1/2 top-80 h-[120px] w-[120px] translate-x-1/2"
              :to="qrCode"
            >
              <picture>
                <RevIllustrationSource
                  :height="120"
                  :media="BreakpointQueries[Breakpoint.XS]"
                  :src="`/img/prompt/qr-code.webp`"
                  type="image/webp"
                  :width="120"
                />
                <RevIllustration
                  :alt="i18n(translations.qrCode)"
                  :height="120"
                  loading="eager"
                  :src="`/img/prompt/qr-code.png`"
                  :width="120"
                />
              </picture>
            </RevButtonBase>
          </div>

          <div class="block md:hidden">
            <RevIllustration
              alt=""
              class="absolute mx-auto"
              :height="120"
              src="/img/prompt/illustration.svg"
              :width="141"
            />
          </div>
        </div>

        <RevButton
          class="my-28 min-w-[250px] md:w-auto"
          :to="link"
          variant="primary"
          @click="trackCTAClick"
        >
          {{ i18n(translations.cta) }}
        </RevButton>
      </RevCard>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import {
  Breakpoint,
  BreakpointQueries,
  getCurrentBreakpoint,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { IconCross } from '@ds/icons/IconCross'

import { TRACKING_ZONE } from './SoftPrompt.constants'
import translations from './SoftPrompt.translations'
import { usePrompt } from './usePrompt'

const i18n = useI18n()
const tracking = useTracking()

const { canDisplayPrompt, onPromptClosed, link, qrCode } = usePrompt()

const isMobile = computed(() => getCurrentBreakpoint() < Breakpoint.MD)

const getVerticalPosition = computed(() => {
  return isMobile.value ? tw`-bottom-9` : tw`top-[120px]`
})

function trackCTAClick() {
  tracking.trackClick({
    zone: TRACKING_ZONE,
    name: 'app_store_download/google_store_download/download_app',
  })
}
</script>
