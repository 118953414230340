import { useHead, useRoute, useRouter, useState } from '#imports'
import { computed } from 'vue'
import { type Router } from 'vue-router'

import {
  type MenuItem,
  type Service,
  getNavigationMainMenu,
} from '@backmarket/http-api/src/api-specs-navigation-experience/navigation/sidebar'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { type Logger, type Owner } from '@backmarket/nuxt-module-logger/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

export interface Item {
  children: Item[] | never[]
  color?: string
  icon?: string
  id: string
  image?: string
  title: string
  url: string
  details?: Service[]
}

function toUrl(locale: string, name: string, id: string): string {
  return `/${locale}/l/${name}/${id}`
}

function mapMenuItemtoItem(
  item: MenuItem,
  router: Router,
  logger: Logger<Owner[]>,
  locale: string,
): Item {
  return {
    children:
      item.children?.map((elt) =>
        mapMenuItemtoItem(elt, router, logger, locale),
      ) || [],
    id: item.id,
    image: item.imageUrl,
    title: item.title,
    url: toUrl(locale, item.name, item.id),
    details: item.meta?.map((meta) => meta.sidebar),
  }
}

export function useNavigation() {
  const items = useState<Item[]>('menu-items', () => [])
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()
  const { trackClick } = useTracking()
  const currentLocale = useI18nLocale()

  const fetchMenuItems = async () => {
    if (items.value.length === 0) {
      const data = await $httpFetch(getNavigationMainMenu)
      if (data) {
        items.value = data.items.map((item) =>
          mapMenuItemtoItem(item, router, logger, currentLocale),
        )
      }
    }

    return items
  }

  const activeMenu = useState<string | undefined>(
    'menu-active',
    () => undefined,
  )
  const isMenuActive = computed(() => activeMenu.value !== undefined)
  const setActiveMenu = (id: string | undefined) => {
    activeMenu.value = id
  }
  const clearActiveMenu = () => {
    activeMenu.value = undefined
  }

  const isOpen = useState('burger-menu-isOpen', () => false)
  useHead({
    bodyAttrs: {
      class: computed(() =>
        isOpen.value ? 'overflow-hidden lg:overflow-auto' : '',
      ),
    },
  })
  const state = computed(() => (isOpen.value ? 'open' : 'closed'))
  const toggle = () => {
    clearActiveMenu()

    isOpen.value = !isOpen.value
    if (isOpen.value) {
      trackClick({ zone: 'nav', name: 'all_product' })
    }
  }

  const isActivePage = (link: string | undefined) => {
    if (!link) return false
    try {
      const url = new URL(link, window.location.origin)

      return route.fullPath === url.pathname
    } catch (err) {
      return route.fullPath === link
    }
  }

  return {
    clearActiveMenu,
    fetchMenuItems,
    setActiveMenu,
    toggle,
    isActivePage,
    activeMenu,
    isMenuActive,
    isOpen,
    items,
    menu: items,
    state,
  }
}
