<template>
  <div :class="{ 'flex w-full': hasTradeInExperiment }">
    <Combobox
      :fullscreen
      :has-trade-in-experiment
      input-id="searchBar-input"
      :popular-searches="popularSearches || []"
      :should-focus-input
      :suggestions
      @blur="handleBlur"
      @change="changeHandler"
      @focus="handleFocus"
      @submit="submitHandler"
    />
  </div>
</template>

<script setup lang="ts">
import { navigateTo, useRouter } from '#imports'
import { computed } from 'vue'

import { getPopular } from '@backmarket/http-api/src/api-specs-search-reco/search/searchPopular'
import { useHttpLazyFetch } from '@backmarket/nuxt-module-http/useHttpLazyFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { type Link, useAlgoliaSearch } from '../composables/useSearch'

import Combobox, { type Item } from './components/Combobox.vue'

withDefaults(
  defineProps<{
    shouldFocusInput?: boolean
    fullscreen?: boolean
    hasTradeInExperiment?: boolean
  }>(),
  {
    shouldFocusInput: false,
    fullscreen: false,
    hasTradeInExperiment: false,
  },
)

const emit = defineEmits(['submit', 'blur', 'focus'])

const { trackFormSubmit, trackSearchAnalytics, trackSearchTerm } = useTracking()
const router = useRouter()
const visitorId = useVisitorId()

const { data: popularSearchesPayload } = useHttpLazyFetch(getPopular, {
  default: () => [],
})

const popularSearches = computed(() => {
  return popularSearchesPayload.value
    ?.map((item) => {
      if (item.link.href)
        return item.highlightedTitle
          ? {
              title: item.title,
              highlightedTitle: item.highlightedTitle,
              id: item.objectID,
              link: item.link,
            }
          : {
              title: item.title,
              id: item.objectID,
              link: item.link,
            }

      return undefined
    })
    .filter((hit): hit is Link => hit !== undefined)
})

const {
  hits: suggestions,
  query: searchQuery,
  queryID,
  index: indexType,
  fetchSearchConfiguration,
  fetchSearchApiKey,
} = useAlgoliaSearch('search-bar', { debounce: true, debounceTime: 500 })
await fetchSearchConfiguration()
await fetchSearchApiKey()

function changeHandler(query: string) {
  searchQuery.value = query
}

const locale = useI18nLocale()
function goToSearchLP({ query }: { query: string }) {
  const link = {
    type: 'internal',
    name: PRODUCT_LIST_ROUTES.SEARCH_RESULTS,
    query: { q: query },
    params: { locale },
  }
  router.push(toNuxtLink(link))
}

function goToProductLP({ index, item }: { index: number; item: Item }) {
  if (item.id) {
    trackSearchAnalytics({
      type: 'click',
      objectId: item.id,
      searchQueryID: queryID.value ?? '',
      tokenId: visitorId,
      position: index + 1,
      index: indexType.value ?? 'unknown',
    })
  }

  navigateTo(toNuxtLink(item.link), { external: item.link.type === 'external' })
}

function submitHandler({
  query,
  index,
  item,
}: {
  query: string
  index: number
  item: Item
}) {
  trackFormSubmit({
    zone: 'header_searchbar',
    name: item?.title ?? query,
    payload: {
      index_used: index,
      search_type: item?.source ?? 'userInput',
    },
  })

  emit('submit')

  trackSearchTerm(query)

  if (item) {
    goToProductLP({ index, item })
  } else if (query) {
    goToSearchLP({ query })
  }
}

function handleFocus() {
  emit('focus')
}
function handleBlur() {
  emit('blur')
}
</script>
