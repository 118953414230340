<template>
  <header
    id="header"
    ref="header"
    class="bg-surface-default-low border-static-default-low sticky top-0 z-10 border-b transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden && !isOpen }"
  >
    <ClientOnly>
      <CountrySwitchRedirection
        v-if="hasCountrySwitchRedirection"
        ref="countrySwitchBannerRef"
      />
    </ClientOnly>
    <HeaderBar data-qa="header-bar" />

    <div class="mx-16 hidden md:block md:px-0 lg:mx-0 lg:px-32">
      <NavMenu class="h-48" />
    </div>
    <NavBurgerMenu v-if="isOpen" class="md:hidden" />
  </header>
</template>

<script setup lang="ts">
import { useAsyncData } from '#imports'
import { computed, ref, watchEffect } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import { useHideOnScroll } from '../composables/useHideOnScroll'
import { useNavigation } from '../composables/useNavigation'

import CountrySwitchRedirection from './CountrySwitch/CountrySwitchRedirection.vue'
import HeaderBar from './HeaderBar.vue'
import NavBurgerMenu from './NavBurgerMenu/NavBurgerMenu.vue'
import NavMenu from './NavMenu/NavMenu.vue'

const { fetchMenuItems, isOpen } = useNavigation()
useAsyncData(fetchMenuItems)

const { hidden: headerIsHidden } = useHideOnScroll()
const { clearActiveMenu } = useNavigation()

watchEffect(() => {
  if (headerIsHidden.value) {
    clearActiveMenu()
  }
})

const experiments = useExperiments()

const countrySwitchBannerRef = ref<HTMLElement | null>()

const hasCountrySwitchRedirection = computed(() => {
  return (
    experiments['experiment.countrySwitchRedirection'] === 'withRedirection'
  )
})
</script>
