<template>
  <div>
    <RevButtonBase
      class="flex w-full items-start gap-12 px-24 py-20 text-left"
      @click="() => setActiveMenu(id)"
    >
      <RevIllustration
        v-if="image"
        alt=""
        class="h-24 w-24"
        :height="24"
        :src="image"
        :width="24"
      />
      <span class="body-1-bold flex-1">
        {{ title }}
      </span>
      <IconChevronRight />
    </RevButtonBase>
    <div
      v-show="activeMenu === id"
      aria-label="submenu"
      class="bg-surface-default-low fixed inset-0 z-10 flex flex-col"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { useNavigation } from '../../composables/useNavigation'

defineProps<{
  title: string
  image?: string
  id: string
}>()

const { setActiveMenu, activeMenu } = useNavigation()
</script>
