<template>
  <div>
    <RevLink
      :aria-label="i18n(translations.cartIconAltText)"
      class="relative flex h-24 w-24 items-center justify-center md:h-40 md:w-40"
      :to="resolveLocalizedRoute({ name: CHECKOUT.CART })"
      @click="
        trackClick({
          zone: 'header',
          name: 'cart',
        })
      "
    >
      <div
        v-if="hasCartItems"
        class="bg-static-info-hi border-overlap-default-low rounded-full absolute right-0 top-0 h-9 w-9 border-1"
      />
      <IconShoppingBag class="h-24 w-24" />
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp, useRouteLocationWithLocale } from '#imports'
import { computed, onMounted } from 'vue'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'
import { storeToRefs } from 'pinia'

import { CHECKOUT } from '~/scopes/checkout/routes-names'

import translations from './TheHeader.translations'

const userStore = useUserStore()

const i18n = useI18n()

const { trackClick } = useTracking()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const { user } = storeToRefs(userStore)
const hasCartItems = computed(() => user.value.cartItemsCount > 0)

// TODO: [CK-3730] Remove this fetch and let the cart page update the cartItemsCount directly (as done in pastrami)
// The user is already fetched in the user plugin on the first load
// We don't need to fetch it again.
// However, we can refresh the user data on next client-side navigation
// (e.g after the user navigates back from the cart page to the home page)
const nuxtApp = useNuxtApp()
if (!nuxtApp.isHydrating) {
  onMounted(async () => {
    await userStore.fetchUser()
  })
}
</script>
