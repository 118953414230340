<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <nav aria-label="menu" class="bg-surface-default-low transition-colors">
    <div
      class="relative w-full"
      :class="[
        !arrivedState.left
          ? `before:from-bg-static-default-low before:absolute before:inset-y-0 before:left-0 before:z-[1] before:h-48 before:w-32 before:bg-gradient-to-r before:to-transparent before:content-['']`
          : '',
        !arrivedState.right
          ? `after:from-bg-static-default-low after:absolute after:inset-y-0 after:right-0 after:z-[1] after:h-48 after:w-32 after:bg-gradient-to-l after:to-transparent after:content-['']`
          : '',
      ]"
    ></div>
    <div class="flex h-full justify-center">
      <ul
        ref="menu"
        class="flex h-full flex-row gap-32 overflow-auto scrollbar-none"
      >
        <li
          v-if="
            isGoodDealsEnabled &&
            isBlackFridayEnabled &&
            isBlackFridaySeason(new Date())
          "
          class="h-full"
        >
          <NavMenuLink
            :color="BLACK_FRIDAY.color"
            :icon="BLACK_FRIDAY.icon"
            :title="BLACK_FRIDAY.title"
            :url="BLACK_FRIDAY.url"
          />
        </li>
        <li
          v-else-if="
            isGoodDealsEnabled &&
            isHolidaysEnabled &&
            isHolidaysSeason(new Date())
          "
          class="h-full"
        >
          <NavMenuLink
            :color="HOLIDAYS.color"
            :icon="HOLIDAYS.icon"
            :title="HOLIDAYS.title"
            :url="HOLIDAYS.url"
          />
        </li>
        <li v-else-if="isGoodDealsEnabled" class="h-full">
          <NavMenuLink
            :color="GOOD_DEALS.color"
            :icon="GOOD_DEALS.icon"
            :title="GOOD_DEALS.title"
            :url="GOOD_DEALS.url"
          />
        </li>

        <li v-for="(item, index) in items" :key="item.id" class="h-full">
          <div
            class="group h-full"
            role="menuitem"
            tabindex="0"
            @blur="hideMenu"
            @focus="
              () =>
                showMenu({ id: item.id, title: item.title, position: index })
            "
            @mouseenter="
              () =>
                showMenu({ id: item.id, title: item.title, position: index })
            "
            @mouseleave="hideMenu"
          >
            <RevLink
              :aria-expanded="activeMenu === item.id"
              class="text-action-default-hi body-2 h-full text-left transition-all duration-700 ease-in-out hover:body-2-bold hover:no-underline"
              :class="{
                'body-2-bold': isActivePage(item.url) && !isMenuActive,
                'text-action-default-low': isMenuActive,
              }"
              :to="item.url"
              :tracking="{
                zone: 'header',
                name: 'department',
                value: item.title,
              }"
              :underlined="false"
              @click.capture="
                (event) => {
                  if (!isHoverMedia && activeMenu !== item.id)
                    event.preventDefault()
                  else hideMenu()
                }
              "
            >
              <div
                class="flex h-full items-center text-nowrap border-t-transparent group-hover:border-b-static-default-hi group-hover:border-y-2"
                :class="{
                  'border-b-static-default-hi border-y-2':
                    isActivePage(item.url) && !isMenuActive,
                }"
              >
                <span class="bold-hack" :title="item.title">
                  {{ item.title }}</span
                >
              </div>
            </RevLink>

            <div v-if="item.children.length" class="absolute inset-x-0 mt-1">
              <Transition
                :css="withTransition"
                enter-active-class="ease-out duration-300"
                enter-from-class="[clip-path:inset(0_0_100%)]"
                enter-to-class="[clip-path:inset(0_0_0%)]"
                leave-active-class="ease-in duration-100"
                leave-from-class="[clip-path:inset(0_0_0%)]"
                leave-to-class="[clip-path:inset(0_0_100%)]"
                @after-enter="withTransition = false"
              >
                <div
                  v-show="activeMenu === item.id"
                  class="bg-surface-default-low flex transition-[clip-path]"
                >
                  <Services
                    v-for="service in item.details"
                    :key="service.name"
                    class="bg-surface-default-mid max-w-256"
                    :service
                  />
                  <NavMenuContent
                    class="flex-1"
                    :items="item.children"
                    :universeTitle="item.title"
                    :universeURL="item.url"
                  />
                </div>
              </Transition>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useAsyncData } from '#app'
import { ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useMediaQuery } from '@backmarket/utils/composables/useMediaQuery'
import { RevLink } from '@ds/components/Link'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { useScroll } from '@vueuse/core'

import { useMegaMenu } from '../../composables/useMegaMenu'
import { useNavigation } from '../../composables/useNavigation'
import {
  isBlackFridaySeason,
  isHolidaysSeason,
} from '../../utils/seasonsHelpers'
import Services from '../NavBurgerMenu/Services.vue'
import t from '../TheHeader.translations'

import NavMenuContent from './NavMenuContent.vue'
import NavMenuLink from './NavMenuLink.vue'

const i18n = useI18n()
const locale = useI18nLocale()

const menu = ref()
const { arrivedState } = useScroll(menu)

const isHoverMedia = useMediaQuery('(hover: hover)')

const {
  features: {
    goodDeals: { enabled: isGoodDealsEnabled },
    blackFriday: { enabled: isBlackFridayEnabled },
    holidays: { enabled: isHolidaysEnabled },
  },
}: {
  features: {
    goodDeals: { enabled: boolean }
    blackFriday: { enabled: boolean }
    holidays: { enabled: boolean }
  }
} = useMarketplace()

const GOOD_DEALS = {
  title: i18n(t.goodDeals),
  children: [],
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/good-deals`,
}

const BLACK_FRIDAY = {
  title: 'Black Friday',
  children: [],
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/black-friday`,
}

const HOLIDAYS = {
  title: i18n(t.holidays),
  children: [],
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/holidays`,
}

const { items, activeMenu, isActivePage, fetchMenuItems } = useNavigation()
const { showMenu, hideMenu, isMenuActive } = useMegaMenu()

useAsyncData(fetchMenuItems)

const withTransition = ref(true)
watch(isMenuActive, () => {
  if (!isMenuActive.value) {
    withTransition.value = true
  }
})
</script>

<style scoped>
.bold-hack::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: content;
  border: 1px solid black;
}
</style>
