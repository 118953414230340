import { type Ref, computed } from 'vue'

import { isCJKCharacter } from '@backmarket/utils/string/isCJKCharacter'
import { IconAvatar } from '@ds/icons/IconAvatar'

export function useAvatar(name: Ref<string | undefined>) {
  const firstName = computed(() => name?.value || '')
  const hasJapaneseOrKoreanFirstName = computed(() =>
    isCJKCharacter(firstName.value),
  )

  const label = computed(() => {
    if (hasJapaneseOrKoreanFirstName.value) return firstName.value

    return firstName.value.charAt(0)
  })

  const thumbnail = computed(() =>
    !firstName.value || hasJapaneseOrKoreanFirstName.value
      ? IconAvatar
      : undefined,
  )

  return {
    label,
    thumbnail,
  }
}
