<template>
  <section
    v-if="service.items.length > 0"
    class="flex flex-col px-24 pb-16 pt-24 md:gap-16 md:px-40 md:py-24"
  >
    <p class="text-static-default-mid body-2">
      {{ service.title }}
    </p>
    <ul class="flex flex-col gap-12 py-12 md:py-0">
      <li v-for="item in service.items" :key="item.id">
        <NavMenuService
          class="w-full"
          :description="item.tagline"
          :icon="getIcon(item.icon || '')"
          :title="item.title"
        />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { type Service } from '@backmarket/http-api/src/api-specs-navigation-experience/navigation/sidebar'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import NavMenuService from '../NavMenu/NavMenuService.vue'

defineProps<{ service: Service }>()

function getIcon(icon: string) {
  return icon === 'trade-in' ? IconSwap : IconSparkles
}
</script>
