<template>
  <div>
    <div class="flex h-full items-center gap-24">
      <RevLink
        v-for="{ label, name, url, icon, classNames } in links"
        :key="name"
        class="flex items-center gap-4"
        data-test="footer-link"
        :to="url"
        :tracking="{ name: name, zone: 'header' }"
        :underlined="false"
      >
        <component
          :is="icon"
          v-if="icon"
          :class="`${classNames} ${variant === 'small' ? 'h-16 w-16' : ''}`"
        />
        <span :class="classNames">
          {{ label }}
        </span>
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../composables/useNavLinks'

const { withTradein = true, variant = undefined } = defineProps<{
  withTradein?: boolean
  variant?: 'small'
}>()

const { links } = useNavLinks({ withTradein, variant })
</script>
