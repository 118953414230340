<template>
  <RevButtonBase
    class="rounded-sm body-1-bold box-border flex gap-4 text-nowrap border px-12 py-7 hover:bg-action-default-min-hover motion-safe:transition-colors motion-safe:duration-200 motion-safe:ease-in"
    :to="`/${locale}/buyback/home`"
    :tracking
  >
    <IconSwap />
    <span>
      {{ i18n(translations.tradeIn) }}
    </span>
  </RevButtonBase>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { IconSwap } from '@ds/icons/IconSwap'

import translations from './TheHeader.translations'

const locale = useI18nLocale()
const i18n = useI18n()

const route = useRoute()

const tracking = computed((): Record<string, string> => {
  const value = route.name?.toString()

  return value
    ? { name: 'buyback', zone: 'header', value }
    : { name: 'buyback', zone: 'header' }
})
</script>
