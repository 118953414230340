<template>
  <div>
    <div id="skip-to-content-wrapper" class="fixed z-50"></div>
    <div id="toast-wrapper" class="fixed z-50">
      <TheToast to="#toast-wrapper" />
    </div>

    <CookiesModal />

    <ClientOnly>
      <SoftPrompt v-if="isSoftPromptAvailable" />
    </ClientOnly>

    <slot name="header">
      <Transition enter-from-class="opacity-0" leave-to-class="opacity-0">
        <RevBackdrop
          v-if="isMenuActive"
          class="z-10 hidden backdrop-blur-2xl transition-opacity duration-200 md:block"
        />
      </Transition>
      <InlineHeader v-if="inlineHeaderEnabled" />
      <TheHeader v-else />
    </slot>

    <slot />

    <slot name="footer">
      <Footer />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from '#app'
import { useHead, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import TheToast from '@backmarket/nuxt-module-toast/TheToast.vue'
import CookiesModal from '@backmarket/nuxt-module-tracking/CookiesModal.vue'
import { RevBackdrop } from '@ds/components/Backdrop'

import Footer from '~/scopes/navigation/components/Footer/Footer.vue'
import InlineHeader from '~/scopes/navigation/components/InlineHeader.vue'
import { useNavigation } from '~/scopes/navigation/composables/useNavigation'

import { useAppsflyer } from '../scopes/appsflyer/composables/useAppsflyer'
import TheHeader from '../scopes/navigation/components/TheHeader.vue'
import SoftPrompt from '../scopes/prompt/SoftPrompt.vue'

import { SOFTPROMPT_WINDOW_HEIGHT_THRESHOLD } from './default.constants'

const { isMenuActive } = useNavigation()

const experiments = useExperiments()

const route = useRoute()
const exp = computed(() => {
  return Object.fromEntries(
    Object.entries(route.query).filter(([key]) =>
      key.startsWith('experiment.'),
    ),
  )
})

const inlineHeaderEnabled = computed(() => {
  return (
    experiments['experiment.inLineHeader'] === 'with' ||
    exp.value['experiment.inLineHeader'] === 'with'
  )
})

useHead({
  htmlAttrs: {
    lang: useI18nLocale(),
  },
})
useAppsflyer()

const { countryCode } = useMarketplace().market
const enabledCountries =
  useRuntimeConfig().public.FF_ENABLE_SOFT_PROMPT_BY_COUNTRY || ''

const windowHeight = ref(0)

const isSoftPromptAvailable = computed(() => {
  const isEnabledInCurrentCountry = enabledCountries.includes(countryCode)
  const isHighEnough = windowHeight.value >= SOFTPROMPT_WINDOW_HEIGHT_THRESHOLD

  return isEnabledInCurrentCountry && isHighEnough
})

onMounted(() => {
  windowHeight.value = window.innerHeight
})
</script>

<style>
/* Safari adds an Apple Smart Banner automatically because of the Universal links (See Doorman config: https://github.com/BackMarket/doorman/tree/77be9c7f8e2af4d0f0fc9fe1678ec8f39ac41c69/static/apple/universal-links)
   We want to hide it because we have our own custom Banner with Appsflyer
*/
[class*='apple-banner'] {
  display: none !important;
}
</style>
