<template>
  <div
    class="flex flex-wrap items-center gap-16 py-16 md:px-20 md:pb-0 lg:gap-56 lg:px-32 lg:py-20"
  >
    <MenuIcon class="block flex-1 grow pl-20 md:hidden md:pl-0" />
    <TheLogo class="h-12 md:h-16 lg:h-20" />
    <ActionLinks class="body-2-bold hidden md:block" :with-tradein="true" />

    <template v-if="hasTradeInSMButton">
      <div class="z-[1] order-last w-full lg:order-none lg:flex-1">
        <SearchBar class="hidden grow md:block" />
        <div class="flex md:hidden">
          <SearchBar
            class="peer/searchbar order-last w-full"
            :has-trade-in-experiment="true"
          />
          <div
            class="mr-12 grid shrink-0 grid-cols-[1fr] transition-[grid-template-columns,margin] duration-300 ease-[cubic-bezier(0.5,0,0.2,1)] peer-focus-within/searchbar:mr-20 peer-focus-within/searchbar:grid-cols-[0fr]"
          >
            <div class="flex justify-end overflow-hidden">
              <TradeInButton class="relative ml-20" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <SearchBar
      v-if="!hasTradeInSMButton"
      class="z-[1] order-last mx-20 w-full grow md:mx-0 lg:order-none lg:flex-1"
    />

    <UserOptions class="flex-1 pr-20 md:pr-0 lg:grow-0" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import ActionLinks from './ActionLinks.vue'
import MenuIcon from './MenuIcon.vue'
import TheLogo from './TheLogo.vue'
import TradeInButton from './TradeInButton.vue'
import UserOptions from './UserOptions.vue'

const experiments = useExperiments()

const hasTradeInSMButton = computed(
  () => experiments['experiment.tradeInMobileEntry'] === 'withTradeInButton',
)
</script>
