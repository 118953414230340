<template>
  <form
    v-click-outside="closeListbox"
    :class="[
      { relative: !fullscreen },
      !hasTradeInExperiment
        ? 'border-action-default-low rounded-sm border'
        : 'peer/form mr-20 grow',
    ]"
    role="search"
    @submit.prevent="submit"
  >
    <Textbox
      :has-trade-in-experiment
      :input-id
      :input-name="SCHEMA_SEARCH_NAME"
      :is-focus="shouldFocusInput"
      :isListboxOpen
      :listboxResultsMessage
      :value="inputValue"
      @change="changeHandler"
      @close-listbox="closeListbox"
      @focus="openListbox"
      @keydown="changeSelectedIndex"
      @submit="submit"
    />
    <div
      v-show="isListboxOpen"
      class="bg-overlap-default-low border-static-default-low text-action-default-hi absolute inset-x-0 divide-y overflow-auto border-t empty:border-0"
      :class="{
        'shadow-long rounded-sm mt-3 max-h-448': !fullscreen,
        'top-48 h-[calc(100vh-48px)] translate-x-full': fullscreen,
      }"
    >
      <Listbox
        v-if="!inputValue && popularSearches.length > 0"
        :items="popularSearches"
        name="popularSearches"
        :selected-index
        :title="i18n(translations.popularSearches)"
        @select="selectIndex"
        @update-input="updateInput"
      />

      <Listbox
        v-else-if="inputValue && suggestions.length > 0"
        :input-value
        :items="suggestions"
        name="suggestions"
        :selected-index
        :title="i18n(translations.suggestedSearches)"
        @select="selectIndex"
        @update-input="updateInput"
      />
    </div>
  </form>

  <div
    v-if="hasTradeInExperiment"
    class="grid grid-cols-[0fr] transition-[grid-template-columns] duration-300 ease-out focus-within:grid-cols-[1fr] peer-focus-within/form:grid-cols-[1fr]"
  >
    <div class="overflow-hidden">
      <button
        class="text-action-default-hi mr-20 whitespace-nowrap px-0 py-8"
        type="reset"
        @click="handleCancel"
      >
        {{ i18n(translations.cancelLabel) }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'

import type { Link } from '../../composables/useSearch'

import translations from './Combobox.translations'
import Listbox from './Listbox.vue'
import textboxTranslations from './Textbox.translations'
import Textbox from './Textbox.vue'

export type Item = Link & {
  source: string
}

const SCHEMA_SEARCH_NAME = 'q'

const props = withDefaults(
  defineProps<{
    shouldFocusInput?: boolean
    popularSearches?: Link[]
    suggestions?: Link[]
    inputId: string
    fullscreen?: boolean
    hasTradeInExperiment?: boolean
  }>(),
  {
    shouldFocusInput: false,
    popularSearches: () => [],
    suggestions: () => [],
    fullscreen: false,
    hasTradeInExperiment: false,
  },
)

const i18n = useI18n()

const selectedIndex = ref(-1)
const isListboxOpen = ref(props.fullscreen)
const inputValue = ref('')

const emit = defineEmits(['change', 'submit', 'focus', 'blur'])

const addSource = (items: Link[], source: string): Item[] =>
  items.map((item) => ({ ...item, source }))

const items = computed(() => {
  const suggestions = addSource(props.suggestions, 'suggestions')
  const popularSearches = addSource(props.popularSearches, 'popularSearches')

  return inputValue.value ? suggestions : popularSearches
})

const listboxResultsMessage = computed(() => {
  const resultsCount = items.value.length
  const searchQuery = inputValue.value
  const messageWhenQuery = i18n(translations.resultsListing, {
    resultsCount,
    searchQuery,
  })

  return inputValue.value
    ? messageWhenQuery
    : i18n(textboxTranslations.textboxPlaceholder)
})

function changeHandler(value: string) {
  inputValue.value = value
  selectedIndex.value = -1
  emit('change', inputValue.value)
}

function closeListbox() {
  if (!props.fullscreen) {
    isListboxOpen.value = false
    emit('blur')
  }
}

function submit() {
  const focusedElement = document.activeElement as HTMLElement
  focusedElement?.blur()
  emit('submit', {
    query: inputValue.value,
    item: items.value?.[selectedIndex.value],
    index: selectedIndex.value,
  })
  closeListbox()
}

function selectIndex(index?: number) {
  if (index != null) selectedIndex.value = index
  submit()
}

function updateInput(value: string) {
  changeHandler(value)
}

function changeSelectedIndex(offset: number) {
  const { length } = items.value
  if (length !== 0) {
    selectedIndex.value = (length + selectedIndex.value + offset) % length
  }
}

function openListbox() {
  if (!props.fullscreen) {
    selectedIndex.value = -1
    isListboxOpen.value = true
    emit('focus')
  }
}

function handleCancel(event: Event) {
  const button = event.target as HTMLButtonElement

  button?.blur()
  inputValue.value = ''
}

onBeforeMount(() => {
  const input = document.querySelector(
    `#${props.inputId}`,
  ) as HTMLInputElement | null

  if (input && input.value) {
    changeHandler(input.value)
  }
})
</script>
