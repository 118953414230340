<template>
  <ul class="flex list-none flex-col gap-4">
    <li v-for="(contentLine, index) in content" :key="index">
      <RevLink
        v-if="isFooterLink(contentLine)"
        class="body-2"
        :name="i18n(contentLine.key)"
        :target="contentLine.type === 'link-external' ? '_blank' : '_self'"
        :to="contentLine.url"
        :underlined="false"
      >
        {{ i18n(contentLine.key) }}
      </RevLink>

      <RevLink
        v-if="isFooterLinkInternal(contentLine)"
        class="body-2"
        :name="i18n(contentLine.key)"
        :prefetch="contentLine.prefetch"
        :to="
          resolveLocalizedRoute({
            name: contentLine.name,
            params: contentLine.params,
          })
        "
        :underlined="false"
      >
        {{ i18n(contentLine.key) }}
      </RevLink>

      <RevLink
        v-if="isFooterButton(contentLine)"
        class="body-2"
        :name="i18n(contentLine.key)"
        :underlined="false"
        @click="contentLine.action"
      >
        {{ i18n(contentLine.key) }}
      </RevLink>

      <div v-if="isFooterPaymentNetworks(contentLine)" class="body-2">
        <span>
          {{ i18n(contentLine.key) }}
        </span>
        <PaymentIcons class="pt-6" />
      </div>

      <div v-if="isFooterPaymentNetworksWithLink(contentLine)">
        <RevLink
          class="body-2"
          target="_blank"
          :to="contentLine.url"
          :underlined="false"
        >
          {{ i18n(contentLine.key) }}
        </RevLink>
        <PaymentIcons class="pt-6" />
      </div>

      <div
        v-if="isFooterSocials(contentLine)"
        class="flex flex-wrap items-center gap-12 pt-6"
        data-test="social-links"
      >
        <RevLink
          v-for="(href, socialName) in contentLine.networks"
          :key="socialName"
          :data-test="`${socialName}-link`"
          itemprop="sameAs"
          :name="`footer_${socialName}`"
          target="_blank"
          :title="`${COMPANY_NAME} - ${socialName}`"
          :to="href"
          :underlined="false"
        >
          <RevIllustration
            alt=""
            class="h-32 w-32"
            :height="30"
            :src="`/img/socials/${socialName}-light.svg`"
            :width="30"
          />
        </RevLink>
      </div>

      <div v-if="isFooterIcons(contentLine)">
        <RevLink
          name="footer_icons"
          target="_blank"
          :to="contentLine.url"
          :underlined="false"
        >
          <RevIllustration
            v-for="icon in contentLine.icons"
            :key="icon.src"
            :alt="icon.name"
            :class="getIconStyle(contentLine.style)"
            :height="icon.height"
            :src="`/img/${icon.src}`"
            :width="icon.width"
          />
        </RevLink>
      </div>

      <div
        v-if="isFooterLogos(contentLine)"
        class="flex flex-wrap justify-start gap-16"
      >
        <template v-for="logo in contentLine.logos">
          <BCorpLogo v-if="logo === 'bcorp'" :key="logo" class="shrink-0" />
          <BBBLogo v-if="logo === 'bbb'" :key="logo" class="shrink-0" />
          <CustomerServiceOfTheYearLogo
            v-if="logo === 'customer-service-of-the-year'"
            :key="logo"
            class="shrink-0"
          />

          <BestECommerceWebsiteLogo
            v-if="logo === 'best-e-commerce-website'"
            :key="logo"
            class="shrink-0"
          />
        </template>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import { PaymentIcons } from '@backmarket/nuxt-layer-payment/methods'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import BBBLogo from './BBBLogo.vue'
import BCorpLogo from './BCorpLogo.vue'
import BestECommerceWebsiteLogo from './BestECommerceWebsiteLogo.vue'
import CustomerServiceOfTheYearLogo from './CustomerServiceOfTheYearLogo.vue'
import {
  type FooterContent,
  type FooterIcons,
  isFooterButton,
  isFooterIcons,
  isFooterLink,
  isFooterLinkInternal,
  isFooterLogos,
  isFooterPaymentNetworks,
  isFooterPaymentNetworksWithLink,
  isFooterSocials,
} from './FooterContent'

const i18n = useI18n()
const runtimeConfig = useRuntimeConfig()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const { COMPANY_NAME } = runtimeConfig.public

export type FooterListProps = {
  content: FooterContent[]
}

defineProps<FooterListProps>()

function getIconStyle(className: FooterIcons['style']) {
  const style = {
    iconSmall: tw`first:w-32 mr-12 w-64`,
    icon: tw`first:ml-0 ml-12 mt-12 w-96`,
  }

  return style[className]
}
</script>
