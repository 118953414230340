import { createHttpEndpoint } from '../../utils'

export type Service = {
  title: string
  name: string
  items: MenuItem[]
}

type Meta = {
  sidebar: Service
}

export type MenuItem = {
  type: string
  id: string
  name: string
  title: string
  tagline?: string
  icon?: string
  imageUrl?: string
  thumbnailUrl?: string
  meta?: Meta[]
  children?: MenuItem[]
}

export type Navigation = {
  id: string
  title: string
  items: MenuItem[]
}

/**
 * Returns the sidebar navigation
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/navigation-api/definition#tag/sidebar/operation/getNavigationSidebarV2}
 */
export const getNavigationMainMenu = createHttpEndpoint<Navigation>({
  method: 'GET',
  operationId: 'navigationFetch',
  path: '/bm/navigation/main',
})
