<template>
  <section class="mt-8 flex flex-col gap-16 p-24 pb-8">
    <RevLink
      v-for="link in links.filter(({ name }) => name !== 'buyback')"
      :key="link.name"
      class="body-2-bold"
      :to="link.href"
      :tracking="{ zone: 'nav', name: link.name }"
      :underlined="false"
      @click="toggle"
    >
      {{ link.label }}
    </RevLink>
  </section>
</template>

<script setup lang="ts">
import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../../composables/useNavLinks'
import { useNavigation } from '../../composables/useNavigation'

const { links } = useNavLinks()
const { toggle } = useNavigation()
</script>
